import QuizService from "@/service/quiz.service";
import * as fb from "@/plugins/firebase";

const quizStore = {
  namespaced: true,

  state: {
    selectedQuiz: {},
    selectedIngredients: [],
    quizList: [],
  },

  getters: {
    quizList(state) {
      return state.quizList;
    },
    selectedQuiz(state) {
      return state.selectedQuiz;
    },
    selectedIngredients(state) {
      return state.selectedIngredients;
    },
  },

  mutations: {
    setQuizList(state, val) {
      state.quizList = val;
    },
    setSelectedQuiz(state, val) {
      state.selectedQuiz = val;
    },
    setSelectedIngredients(state, val) {
      state.selectedIngredients = val;
    },
  },

  actions: {
    async selectQuiz({ commit }, quizId) {
      if (this.state.quiz.quizList) {
        let filteredQuizzes = this.state.quiz.quizList.filter((q) => {
          return q.quizId === quizId;
        });

        if (filteredQuizzes) {
          let quiz = filteredQuizzes[0];

          let ingredients = [];

          quiz.selectedProducts.forEach(async (p) => {
            ingredients.push(...p.ingredients);
          });

          await Promise.all(
            ingredients.map(async (ingredient) => {
              if (ingredient.hasImage) {
                ingredient.url = await fb.util.downloadImageUrl(
                  quiz.bucketName,
                  "ingredients",
                  ingredient.id,
                  ingredient.imageFormat
                );
              }
              return ingredient;
            })
          );

          commit("setSelectedQuiz", quiz);
          commit("setSelectedIngredients", ingredients);
        }
      }
    },
    async loadQuizzes({ commit }) {
      let quizzes = await QuizService.loadQuizzes();
      if (quizzes) {
        quizzes.forEach((prds) => {
          if (prds.selectedProducts) {
            prds.selectedProducts.sort((a, b) => (a.name > b.name ? -1 : 1));
          }
        });

        commit("setQuizList", quizzes);
      }
    },
  },
};
export default quizStore;
