import myAxios from "@/plugins/axios";

export default {
  async loadDialog(campaignId) {
    return myAxios.privateInstance
      .get(`/admin/quiz/${campaignId}`)
      .catch((error) => {
        throw new Error(error);
      });
  },
  async loadDialogs() {
    return myAxios.privateInstance.get(`/campaign/mydialogs`).catch((error) => {
      throw new Error(error);
    });
  },
  answerDialog(quiz) {
    return myAxios.privateInstance
      .post(`/user-quiz/dialog/answer`, quiz)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
};
