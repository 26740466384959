import myAxios from "@/plugins/axios";

export default {
  async listUnusedStamps() {
    return myAxios.privateInstance.get(`/loyalty`).catch((error) => {
      throw new Error(error);
    });
  },
  async claimRewards() {
    return myAxios.privateInstance.post(`/loyalty/use`).catch((error) => {
      throw new Error(error);
    });
  },
};
