<template lang="pug">
  v-app
    v-main.pt-0
      router-view
      notification
      //.update-dialog(v-if='prompt')
        .update-dialog__content
          | A new version is available.
        .update-dialog__actions
          button.update-dialog__button.update-dialog__button--confirm(@click='update')
            | Update
          button.update-dialog__button.update-dialog__button--cancel(@click='prompt = false')
            | Ignore

</template>

<script>
import Notification from "@/components/notification/Notification";

export default {
  name: "App",

  components: { Notification },
  mounted() {},
  methods: {
    async update() {
      this.prompt = false;
      await this.$workbox.messageSW({ type: "SKIP_WAITING" });
    },
  },

  data() {
    return {
      prompt: false,
    };
  },

  created() {
    /*console.log(`this.$workbox: ${this.$workbox}`)

    if (this.$workbox) {
      this.$workbox.addEventListener('installed', (event) => {
        console.log(`event: ${event}`)
        if (event.isUpdate) {
          console.log(`event.isUpdate: ${event.isUpdate}`)
          this.prompt = true;
        }
      });

    }*/
  },
};
</script>

<style lang="scss">
.update-dialog {
  position: fixed;
  left: 50%;
  bottom: 64px;
  transform: translateX(-50%);
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 12px;
  max-width: 576px;
  color: white;
  background-color: #2c3e50;
  text-align: left;

  &__actions {
    display: flex;
    margin-top: 8px;
  }

  &__button {
    margin-right: 8px;

    &--confirm {
      margin-left: auto;
    }
  }
}
</style>
