import { ANSWER_TYPES } from "@/constant/app";

const DialogUtil = {
  isOneOptionQuestion: (question) => {
    return question && question.answerType === ANSWER_TYPES.ONE;
  },
  isMultipleOptionsQuestion: (question) => {
    return question && question.answerType === ANSWER_TYPES.MULTIPLE;
  },
  isTextQuestion: (question) => {
    return question && question.answerType === ANSWER_TYPES.TEXT;
  },
  isFirstQuestion: (question) => {
    return question && question.order === 1;
  },
  delay: (ms) => new Promise((res) => setTimeout(res, ms)),
};

export default DialogUtil;
