import * as fb from "@/plugins/firebase";
import myAxios from "@/plugins/axios";

export default {
  async loadQuiz(quizId) {
    return myAxios.privateInstance
      .get(`/user-quiz/${quizId}`)
      .catch((error) => {
        throw new Error(error);
      });
  },
  async loadQuizzes() {
    return myAxios.privateInstance.get(`/user-quiz`).catch((error) => {
      throw new Error(error);
    });
  },
  async loadIngredientsImages(bucketName, ingredients) {
    await Promise.all(
      ingredients.map(async (ingredient) => {
        if (ingredient.hasImage) {
          ingredient.url = await fb.util.downloadImageUrl(
            bucketName,
            "ingredients",
            ingredient.id,
            ingredient.imageFormat
          );
        }
        return ingredient;
      })
    );

    return ingredients;
  },
};
