import Vue from "vue";
import VueRouter from "vue-router";
import * as fb from "@/plugins/firebase";
import { auth } from "@/plugins/firebase";

Vue.use(VueRouter);

const ROUTE_PATHS = Object.freeze({
  USER_HOME: "/home",
  APP_HOME: "/",
  LOGIN: "/login",
  AUTH: "/login/auth",
  REGISTER: "/login/register",
  FORGOT_PASSWORD: "/login/forgot-password",
  CONFIRM_PASSWORD: "/login/confirm-password",
  CHANGE_PASSWORD: "/login/change-password",
  ORDER_HOME: "/order",
  ACCOUNT_HOME: "/account",
  DIALOG_FLOW: "/dialog",
  QUIZ_HOME: "/quiz",
  QUIZ_DETAILS: "/quiz/detail",
  LOYALTY_HOME: "/loyalty",
  STORIES_HOME: "/story",
  CONTACTUS_HOME: "/contactus",
  STORIES_VIEWER: "/story/:id",
});

const routes = [
  {
    path: ROUTE_PATHS.USER_HOME,
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "UserHome" */ "@/views/UserHome.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: ROUTE_PATHS.APP_HOME,
    name: "LoginHome",
    component: () =>
      import(/* webpackChunkName: "AppHome" */ "@/views/AppHome.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: ROUTE_PATHS.LOGIN,
    name: "Login",
    component: () =>
      import(
        /* webpackChunkName: "LoginContainer" */ "@/views/login/LoginContainer.vue"
      ),
    children: [
      {
        path: ROUTE_PATHS.AUTH,
        name: "Auth",
        component: () =>
          import(/* webpackChunkName: "Auth" */ "@/views/login/Auth.vue"),
      },
      {
        path: ROUTE_PATHS.REGISTER,
        name: "Register",
        component: () =>
          import(
            /* webpackChunkName: "Register" */ "@/views/login/Register.vue"
          ),
      },
      {
        path: ROUTE_PATHS.FORGOT_PASSWORD,
        name: "ForgotPassword",
        component: () =>
          import(
            /* webpackChunkName: "ForgotPassword" */ "@/views/login/ForgotPassword"
          ),
        beforeEnter(to, from, next) {
          const user = fb.auth.currentUser;
          if (user) {
            next({ name: "Home" });
          } else {
            next();
          }
        },
      },
      {
        path: ROUTE_PATHS.CONFIRM_PASSWORD,
        name: "ConfirmPasswordReset",
        component: () =>
          import(
            /* webpackChunkName: "ConfirmPasswordReset" */ "@/views/login/ConfirmPasswordReset"
          ),
      },
      {
        path: ROUTE_PATHS.CHANGE_PASSWORD,
        name: "ChangePassword",
        component: () =>
          import(
            /* webpackChunkName: "ChangePassword" */ "@/views/login/ChangePassword"
          ),
      },
    ],
  },
  {
    path: ROUTE_PATHS.ORDER_HOME,
    name: "OrderHome",
    component: () =>
      import(/* webpackChunkName: "OrderHome" */ "@/views/order/OrderHome"),
  },
  {
    path: ROUTE_PATHS.ACCOUNT_HOME,
    name: "AccountHome",
    component: () =>
      import(
        /* webpackChunkName: "AccountHome" */ "@/views/account/AccountHome"
      ),
  },
  {
    path: ROUTE_PATHS.DIALOG_FLOW + "/:id",
    name: "DialogFlow",
    component: () =>
      import(/* webpackChunkName: "DialogFlow" */ "@/views/dialog/DialogFlow"),
  },
  {
    path: ROUTE_PATHS.QUIZ_HOME,
    name: "QuizHome",
    component: () =>
      import(/* webpackChunkName: "QuizHome" */ "@/views/quiz/QuizHome"),
  },
  {
    path: ROUTE_PATHS.QUIZ_HOME + "/:id",
    name: "QuizDetails",
    component: () =>
      import(/* webpackChunkName: "QuizDetails" */ "@/views/quiz/QuizDetails"),
    props: true,
  },
  {
    path: ROUTE_PATHS.LOYALTY_HOME,
    name: "LoyaltyHome",
    component: () =>
      import(
        /* webpackChunkName: "LoyaltyHome" */ "@/views/loyalty/LoyaltyHome"
      ),
  },
  {
    path: ROUTE_PATHS.STORIES_HOME,
    name: "StoryHome",
    component: () =>
      import(/* webpackChunkName: "StoryHome" */ "@/views/stories/StoriesHome"),
  },
  {
    path: ROUTE_PATHS.STORIES_VIEWER,
    name: "StoriesViewer",
    component: () =>
      import(
        /* webpackChunkName: "StoryViewer" */ "@/views/stories/StoriesViewer"
      ),
  },
  {
    path: ROUTE_PATHS.CONTACTUS_HOME,
    name: "ContactUsHome",
    component: () =>
      import(
        /* webpackChunkName: "ContactUsHome" */ "@/views/contactus/ContactUsHome"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  if (requiresAuth && !auth.currentUser) {
    next(ROUTE_PATHS.APP_HOME);
  } else {
    next();
  }
});

export { router, ROUTE_PATHS };
