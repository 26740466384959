export const ANSWER_TYPES = Object.freeze({
  ONE: "ONE",
  MULTIPLE: "MULTIPLE",
  TEXT: "TEXT",
  UPLOAD: "UPLOAD",
  RATING: "RATING",
});

export const TRANSLATION = Object.freeze({
  DEFAULT_LANGUAGE: "lt",
  FALLBACK_LANGUAGE: "lt",
  SUPPORTED_LANGUAGES: ["en", "lt"],
});

export const QUESTION_PARAMETER = Object.freeze({
  NAME: "NAME",
  BOTTLE_NAME: "BOTTLE_NAME",
  REGISTER: "REGISTER",
  GOALS: "GOALS",
  HAIR_TYPE_1: "HAIR_TYPE_1",
  HAIR_TYPE_2: "HAIR_TYPE_2",
  HAIR_TYPE_3: "HAIR_TYPE_3",
});

export const NOTIFICATION_TYPE = Object.freeze({
  SUCCESS: "SUCCESS",
  WARN: "WARN",
  ERROR: "ERROR",
  INFO: "INFO",
});

export const ACTION_BUTTON_TYPE = Object.freeze({
  MAKE_ORDER: "MAKE_ORDER",
  TAKE_QUIZ: "TAKE_QUIZ",
  READ_LETTERS: "READ_LETTERS",
  SEE_ORDERS: "SEE_ORDERS",
});

export const STATE_IMAGE = Object.freeze({
  SHOWER: "SHOWER",
  CONFUSED: "CONFUSED",
});
