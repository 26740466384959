import Vue from "vue";
import Vuex from "vuex";
import auth from "@/store/auth.store";
import notification from "@/store/notification.store";
import order from "@/store/order.store";
import dialog from "@/store/dialog.store";
import quiz from "@/store/quiz.store";
import loyalty from "@/store/loyalty.store";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: { auth, notification, order, dialog, quiz, loyalty },
});
