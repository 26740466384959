import LoyaltyService from "@/service/loyalty.service";

const loyaltyStore = {
  namespaced: true,

  state: {
    stamps: [],
  },

  getters: {
    stamps(state) {
      return state.stamps;
    },
  },

  mutations: {
    setStamps(state, val) {
      state.stamps = val;
    },
  },

  actions: {
    async listUnusedStamps({ commit }) {
      let stamps = await LoyaltyService.listUnusedStamps();
      commit("setStamps", stamps);
    },
    async claimRewards({ commit }) {
      let res = await LoyaltyService.claimRewards();

      if (res) {
        commit("setStamps", []);
      }

      return res;
    },
  },
};
export default loyaltyStore;
