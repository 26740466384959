import OrderService from "@/service/order.service";

const orderStore = {
  namespaced: true,

  state: {
    orders: [],
  },

  getters: {
    orders(state) {
      return state.orders;
    },
  },

  mutations: {
    setOrders(state, val) {
      state.orders = val;
    },
  },

  actions: {
    async listAll({ commit }) {
      let orders = await OrderService.listAll();
      commit("setOrders", orders);
    },
  },
};
export default orderStore;
