import myAxios from "@/plugins/axios";

export default {
  async load(user) {
    return myAxios.privateInstance
      .get(`/user?email=${user.email || ""}`)
      .catch((error) => {
        throw new Error(error);
      });
  },
  contactUs(obj) {
    return myAxios.privateInstance
      .post(`/user/contact-us`, obj)
      .catch((error) => {
        throw new Error(error);
      });
  },
};
