import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import lt from "vuetify/lib/locale/lt";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#ca987f",
        secondary: "#6a6f5b",
        accent: "#f2e4d9",
        error: "#7A4014",
        info: "#3E4E50",
        success: "#4CAF50",
        warning: "#FFC107",
      },
    },
  },
  lang: {
    locales: { lt },
    current: "lt",
  },
  icons: {
    iconfont: "fa",
  },
});
