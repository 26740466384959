import * as fb from "@/plugins/firebase";
import DialogUtil from "@/plugins/dialog.util";
import DialogService from "@/service/dialog.service";

const dialogStore = {
  namespaced: true,

  state: {
    dialogs: [],
    questions: null,
    dialog: null,
    campaignId: null,
    dialogResult: null,
    messages: [],
  },

  getters: {
    dialogs(state) {
      return state.dialogs;
    },
  },

  mutations: {
    setDialogs(state, dialogs) {
      state.dialogs = dialogs;
    },

    setQuestions(state, questions) {
      state.questions = questions;
    },

    setMessages(state, messages) {
      state.messages = messages;
    },

    updateQuestionAnswer(state, question) {
      state.questions.filter((q) => {
        if (q.id === question.id) {
          q.answer = question.answer;
          //console.log('answered question', question.id, question.answer)
        }
      });
    },

    updateAnsweredQuestions(state, questions) {
      state.questions = questions;
    },

    dialogSaved(state, dialogForm) {
      state.questions = dialogForm.questions;
      state.dialogPicture.dialogId = dialogForm.dialogId;
    },

    setDialogConfiguration(state, dialogConfiguration) {
      state.configurations.dialog = dialogConfiguration;
    },
    setDialogPicture(state, file) {
      state.dialogPicture.file = file;
    },
    setCampaignId(state, campaignId) {
      state.campaignId = campaignId;
    },
    setDialog(state, dialog) {
      state.dialog = dialog;
    },
    setDialogResult(state, dialogResult) {
      state.dialogResult = dialogResult;
    },
  },

  actions: {
    async loadDialogs({ commit }) {
      let dialogs = await DialogService.loadDialogs();

      if (dialogs) {
        let getImageURL = async (dialog) => {
          if (dialog.bucketName) {
            dialog.url = await fb.util.downloadImageUrl(
              dialog.bucketName,
              "",
              "image",
              "png"
            );
          }
          return dialog;
        };
        dialogs = await Promise.all(dialogs.map(getImageURL));
      }

      commit("setDialogs", dialogs || []);
    },
    async loadDialog({ commit }, campaignId) {
      let questions = [];

      commit("setCampaignId", campaignId);

      await DialogService.loadDialog(campaignId).then(async (dialog) => {
        if (dialog.questions) {
          let prepareQuestionData = async (question) => {
            question.images = false;

            let multipleQuestion =
              DialogUtil.isMultipleOptionsQuestion(question);

            question.answer = multipleQuestion ? [] : null;

            if (question.answers) {
              await Promise.all(
                question.answers.map(async (answer) => {
                  if (answer.hasImage) {
                    answer.url = await fb.util.downloadImageUrl(
                      dialog.bucketName,
                      "answers",
                      answer.id,
                      "jpg"
                    );

                    if (!question.images) {
                      question.images = !!answer.hasImage;
                    }
                  }
                  return answer;
                })
              );
            }

            return question;
          };
          questions = await Promise.all(
            dialog.questions.map(prepareQuestionData)
          );
        }

        commit("setDialog", dialog);
      });

      commit("setQuestions", questions);
    },

    answerQuestion({ commit }, question) {
      commit("updateQuestionAnswer", question);
    },

    answerQuestions({ commit }, questions) {
      commit("updateAnsweredQuestions", questions);
    },

    async answerDialog() {
      let dialog = {
        campaignId: this.state.dialog.campaignId,
        questions: [],
      };

      dialog.questions = this.state.dialog.questions.map((question) => {
        let answeredQuestion = {};
        if (DialogUtil.isOneOptionQuestion(question)) {
          answeredQuestion.selectedAnswers = [{ id: question.answer.id }];
        } else if (DialogUtil.isMultipleOptionsQuestion(question)) {
          answeredQuestion.selectedAnswers = question.answer.map((answer) => {
            return {
              id: answer.id,
            };
          });
        } else {
          answeredQuestion.answeredText = question.answer;
        }

        return {
          id: question.id,
          answeredQuestion: answeredQuestion,
        };
      });

      return await DialogService.answerDialog(dialog);
    },
  },
};
export default dialogStore;
